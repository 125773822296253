
// THIS FILE IS AUTOGENERATED AS PART OF THE EXTENSION AND MODE PLUGIN PROCESS.
// IT SHOULD NOT BE MODIFIED MANUALLY 
import extensions0 from '@ohif/extension-cornerstone';
import extensions1 from '@ohif/extension-measurement-tracking';
import extensions2 from '@ohif/extension-cornerstone-dicom-sr';
import extensions3 from '@ohif/extension-default';
import extensions4 from '@ohif/extension-dicom-microscopy';
import extensions5 from '@ohif/extension-dicom-pdf';
import extensions6 from '@ohif/extension-dicom-video';
import extensions7 from '@ohif/extension-tmtv';
import extensions8 from '@ohif/extension-cornerstone-dicom-seg';
import extensions9 from '@ohif/extension-cornerstone-dicom-rt';
import modes0 from '@ohif/mode-longitudinal';
import modes1 from '@ohif/mode-microscopy';
import modes2 from '@ohif/mode-tmtv';
const extensions = [];
const modes = [];
const modesFactory = [];
window.extensions = extensions;
window.modes = modes;

extensions.push(extensions0);
extensions.push(extensions1);
extensions.push(extensions2);
extensions.push(extensions3);
extensions.push(extensions4);
extensions.push(extensions5);
extensions.push(extensions6);
extensions.push(extensions7);
extensions.push(extensions8);
extensions.push(extensions9);
modes.push(modes0);
modes.push(modes1);
modes.push(modes2);


// Add a dynamic runtime loader
export default async () => {
 for(const modeFactory of modesFactory) {
  const newModes = await modeFactory(modes,extensions);
  newModes.forEach(newMode => modes.push(newMode));
}
}


async function loadRuntimeImports(config) {
  if (config && config.modes && config.modes.length) {
    for (const modeName of config.modes) {
      const existingMode = modes.find(mode => mode.id === modeName);
      if (!existingMode) {
        if (modeName === `@ohif/mode-test`) {
          const mode = await import(`@ohif/mode-test`);
          window.modes.push(mode.default);
          const extension = await import(`@ohif/extension-test`);
          window.extensions.push(extension.default);
        }
      }
    };
  }
}
export { loadRuntimeImports };

